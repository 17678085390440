* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: #181818;
  color: #fff;
  margin: 0 auto;
  max-width: 1920px;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar{
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track{
  background-color: #181818;
}

::-webkit-scrollbar-thumb{
  background-color: #DBD1C4;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #A69E94;
}

.overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  z-index: 10;
}

.div-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.button-topo-pagina {
  display: none;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  position: fixed;
  bottom: 3%;
  right: 3%;
  border: none;
  padding-top: 1px;
  border-radius: 50%;
  color: #181818;
  font-size: 30px;
  cursor: pointer;
  transition: all .3s ease;
  z-index: 3;
}

.button-topo-pagina:hover {
  opacity: .6;
}

.alert-ativado, .alert-desativado {
  position: fixed;
  left: 10px;
  background: #fff;
  color: #181818;
  border-radius: 5px;
  min-width: 200px;
  max-width: 400px;
  padding: 5px 10px;
  z-index: 9;
  transition: .5s;
}

.alert-desativado {
  bottom: -100px;
}

.alert-ativado {
  bottom: 25px;
}

.alert-ativado h2, .alert-desativado h2 {
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.alert-ativado p, .alert-desativado p {
  font-size: .8rem;
  font-weight: 600;
}

.h2-titulo-sections {
  position: relative;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 5;
}

.swiper {
  width: 100%;
  height: max-content;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  max-width: max-content;
  margin-right: 10px;
}

.swiper-slide:last-child {
  margin-right: 0;
}

.link-contato {
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px 13px;
  background: #303030;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  z-index: 9;
  overflow: hidden;
  transition: all .3s ease;
}

.link-contato:hover {
  background: #fff;
  color: #181818;
  width: min-content;
}

.link-contato::after {
  content: 'ajuda';
  font-weight: 600;
  font-size: .8rem;
  margin-left: 20px;
}

.link-contato:hover::after {
  margin-left: 5px;
}

@media (max-width: 800px) {
    
  .h2-titulo-sections {
      font-size: 1rem;
  }
}

@media (max-width: 600px) {
    
  .h2-titulo-sections, .alert-ativado h2, .alert-desativado h2 {
      font-size: .9rem;
  }

  .alert-ativado p, .alert-desativado p {
    font-size: .7rem;
  }

  .alert-ativado, .alert-desativado {
    left: 10px;
    margin-right: 50px;
    min-width: 95%;
    max-width: 95%;
  }

  .alert-ativado {
    bottom: 70px;
  }

  .link-contato {
    display: none;
  }

}

.span-version {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 50px;
  background: #fff;
  color: #000;
  font-size: .65rem;
  font-weight: 600;
  z-index: 9999;
}















.div-noscript {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.div-noscript span {
	position: fixed;
	top: 20px;
	left: 50%;
	min-width: min-content;
	transform: translateX(-50%);
	padding: 10px 15px;
	background: #fff;
	border-radius: 50px;
	color: #181818;
	font-size: 1rem;
	font-weight: 600;
	white-space: nowrap;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	height: 100vh;
	background: #181818;
}

.loader-container img {
	animation: opacityImg 4s linear infinite;
	width: 200px;
}

.loader {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	margin-top: 10px;
}

.loader div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes opacityImg {
	0% {
		opacity: .5;
	}

	25% {
		opacity: 1;
	}

	50% {
		opacity: .5;
	}

	75% {
		opacity: 1;
	}

	100% {
		opacity: .5;
	}
}

@keyframes loader {
	0% {
		top: 28px;
		left: 28px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: -1px;
		left: -1px;
		width: 58px;
		height: 58px;
		opacity: 0;
	}
}

@media (max-width: 600px) {

	::-webkit-scrollbar {
    width: 3px;
  }
}

@media (max-width: 500px) {

	.div-noscript span {
		font-size: 3vw;
	}
}